<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'
import { Input, Select } from 'ant-design-vue'
import DrawerFormModal from '@/components/DrawerFormModal'
export default {
  name: 'scenic-spot-guide-groggery',
  data() {
    return {
      ...api.command.getState(),
      language_type: [],
      storeData: [],
      relationIdType: [],
      showModal: false,
      formDetail: {
        scenicGuideNameList: [],
      },
    }
  },
  mounted() {
    this.getList()
    getAction('/admin/dict/codes?codes=language_type', {}, '/bpi').then((e) => {
      this.language_type = (e.data['language_type'] || []).map((e) => ({
        name: e.name,
        value: e.id,
      }))
    })
  },
  methods: {
    getList() {
      api.command.getList.call(this, {
        url: '/farmScenicGuideDetails/page',
        current: 1,
        params: {
          scenicGuideId: this.$route.query.id,
        },
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
        },
        {
          dataIndex: 'mapIcon',
          title: '图片',
          align: 'left',
          customRender: (text) => {
            return <img src={text} style={{ width: '30px', height: '30px' }} />
          },
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '位置',
          type: 'badge',
          customRender: (text, records) => {
            return `经度${records.lon} 纬度${records.lat}`
          },
        },
        {
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  getAction(`/farmScenicGuideDetails/detail?id=${records.id}`).then((e) => {
                    this.showModal = true
                    this.formDetail = e.data
                    this.formDetail.dw = e.data.lat + ',' + e.data.lon
                  })
                },
              },
              {
                name: '删除',
                display: true,
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  delAction(`/farmScenicGuideDetails?id=${records.id}`).then((e) => {
                    this.$message.success('删除成功')
                    this.getList()
                  })
                },
              },
            ]
          },
        },
      ]
    },
    storeSearch(name = '') {
      getAction(`/hotel/list?name=${name}`).then((e) => {
        this.relationIdType = (e.data || []).map((e) => ({
          value: e.id,
          name: e.name,
          ...e,
        }))
      })
    },
    openModal(
      { form = { scenicGuideNameList: [] } } = {
        form: { scenicGuideNameList: [] },
      }
    ) {
      const { id } = this.$route.query
      apiTool.showDrawer({
        title: '基本信息',
        width: '800px',
        formData: form,
        success: (data) => {
          if (form.id) {
            putAction(
              form.id ? '/farmScenicGuideDetails/update' : '/farmScenicGuideDetails/save',
              {
                scenicGuideId: id,
                // ...form,
                ...data.data,
                lon: data.data.lng,
              },
              '/operation'
            ).then((e) => {
              this.$message.success(form.id ? '编辑成功' : '添加成功')
              this.getList()
              data.setHidden()
            })
          } else {
            postAction(form.id ? '/farmScenicGuideDetails/update' : '/farmScenicGuideDetails/save', {
              scenicGuideId: id,
              // ...form,
              ...data.data,
              lon: data.data.lng,
            }).then((e) => {
              this.$message.success(form.id ? '编辑成功' : '添加成功')
              this.getList()
              data.setHidden()
            })
          }
        },
      })
    },
    onSubmit(form) {
      const { id } = this.$route.query
      form.bannerUrl = form.mapIcon
      if (!form.mapIconLat && !form.mapIconLon) {
        form.mapIconLat = form.lat
        form.mapIconLon = form.lon
      }
      if (form.id) {
        putAction(
          '/farmScenicGuideDetails/update',
          {
            scenicGuideId: id,
            ...form,
          },
          '/operation'
        ).then((e) => {
          this.$message.success('编辑成功')
          this.getList()
          this.showModal = false
        })
      } else {
        postAction('/farmScenicGuideDetails/save', {
          scenicGuideId: id,
          ...form,
        }).then((e) => {
          this.$message.success('添加成功')
          this.getList()
          this.showModal = false
        })
      }
    },
    getFormData() {
      return [
        {
          form: [
            {
              name: '关联酒店',
              type: 'dropDownInput',
              cols: 24,
              key: 'name',
              onInputSearch: this.storeSearch,
              valueKey: 'relationId',
              typeData: this.relationIdType,
              inputProps: {
                placeholder: '选择关联酒店',
                addonBefore: <a-icon type="search" />,
              },
              onMenuClick: (data) => {
                this.formDetail.lat = data.lat
                this.formDetail.lon = data.lon
                this.formDetail['dw'] = `${data.lat},${data.lon}`
                // this.storeSearchRoomName(data.id)
                // this.detail.linkShopId = data.linkShopId
                // this.detail.linkShopName = data.linkShopName
                // this.detail.roomId = []
                // this.detail.roomName = ''
                // this.$forceUpdate()
              },
              rules: [
                {
                  required: true,
                },
              ],
            },
            // {
            //   name: '弹窗预览图',
            //   type: 'upload',
            //   key: 'bannerUrl',
            // },
            {
              name: '地图图标',
              type: 'upload',
              key: 'mapIcon',
            },
            {
              name: '经纬度',
              type: 'latlng',
              key: 'dw',
            },
            {
              name: '手绘地图经纬度',
              type: 'latlngGroup',
              keys: ['mapIconLat', 'mapIconLon'],
            },
            {
              name: 'VR链接',
              type: 'input',
              key: 'vrUrl',
              cols: 24,
              maxLength: 400,
              props: {
                maxLength: 400,
              },
            },
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: this.formDetail.scenicGuideNameList,
              showPagination: false,
              tableType: 'editDelete',
              showFootButton: true,
              columns: [
                {
                  title: '语言类型',
                  align: 'center',
                  dataIndex: 'nameType',
                  customRender: (text, records, index) => {
                    return (
                      <Select
                        value={text}
                        onChange={(e) => {
                          this.formDetail.scenicGuideNameList[index]['nameType'] = e
                          records.nameType = e
                          this.$forceUpdate()
                        }}
                      >
                        {this.language_type.map((e) => {
                          return <Select.Option value={e.value}>{e.name}</Select.Option>
                        })}
                      </Select>
                    )
                  },
                },
                {
                  title: '内容',
                  align: 'center',
                  dataIndex: 'name',
                  customRender: (text, records) => {
                    return (
                      <Input
                        value={text}
                        onInput={(e) => {
                          records.name = e.target.value
                        }}
                      />
                    )
                  },
                },
                {
                  dataIndex: 'a8',
                  title: '操作',
                  type: 'buttonGroup',
                  typeData: ({ records, index }) => {
                    return [
                      {
                        name: '删除',
                        display: true,
                        type: 'pop',
                        popTitle: '确认是否删除吗?',
                        onClick: () => {
                          this.formDetail.scenicGuideNameList.splice(index, 1)
                        },
                      },
                    ]
                  },
                },
              ],
            },
          ],
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          onClick: () => {
            this.showModal = true
            this.formDetail = {
              scenicGuideNameList: [],
            }
            // this.openModal()
          },
        },
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: (data) => {
        //         let idList = data.map((e) => e.id)
        //         return api.command.delPostArr.call(this, {
        //           url: '/audit/farmAuditFeedback/deleteBatch',
        //           params: { idList },
        //         })
        //       },
        //     },
        //   ],
        // },
      ]
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
          typeData={{
            relationIdType: this.relationIdType,
          }}
          params={{
            scenicGuideId: this.$route.query.id,
          }}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="基本信息"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={this.getFormData()}
          form={this.formDetail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
